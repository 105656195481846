(function megaMenuScript($, Drupal) {
  Drupal.behaviors.megaMenu = {
    attach(context) {

      // JS for the mobile version of the mega menu
      var $subMenuToggle = $(".mega-menu__small-screens .sub-menu-item-toggle");
      $subMenuToggle.unbind().click(function () {
        let expanded = $(this).attr('aria-expanded') === 'true' || false;
        $(this)
          .toggleClass("sub-menu-item-toggle--closed")
          .toggleClass("sub-menu-item-toggle--opened");
        $(this)
          .siblings(".menu")
          .slideToggle();
        $(this)
          .attr('aria-expanded', !expanded);
        $(this)
          .parent()
          .toggleClass("menu-item--closed");
        $(this)
          .parent()
          .toggleClass("menu-item--opened");
      });

      // JS for the Large Screen version of the mega menu.
      var $megaMenu = $('.header__mega-menu--large-screens .mega-menu');
      var $megaMenuTopLevelItems = $('.header__mega-menu--large-screens .menu-item--level-top > a, .header__mega-menu--large-screens .menu-item--level-top .recite-ele > a');
      var $megaMenuDropdown = $('.header__mega-menu--large-screens .mega-menu__dropdown');
      var $sideBarMenuItems = $('.header__mega-menu--large-screens .menu-item__sidebar > a');
      var $firstSidebarMenuItem = $('.header__mega-menu--large-screens .menu-item__sidebar.menu-item--1');
      var $mainContentMenuItems = $('.header__mega-menu--large-screens .mega-menu__dropdown-main-item');
      var $downKey = 40;
      var $rightKey = 39;

      // This small function, sets the first item in the sidebar as the active item.
      // It is called when you hover/focus on a Top Level Menu Item, via the showMegaMenuDropdown function.
      function activateFirstSidebarMenuItem() {
        $sideBarMenuItems.removeClass('menu-item__sidebar--active');
        $sideBarMenuItems.addClass('menu-item__sidebar--inactive');
        $firstSidebarMenuItem.addClass('menu-item__sidebar--active');
      }

      // This function simply adds the --active class and removes the --inactive class for the dropdown
      // in the mega menu.
      // It is called when you hover/focus on a Top Level Menu Item, via the activateMegaMenuDropdown
      function showMegaMenuDropdown() {
        $megaMenuDropdown.addClass('mega-menu__dropdown--active');
        $megaMenuDropdown.removeClass('mega-menu__dropdown--inactive');
        activateFirstSidebarMenuItem();
      }

      // This function removes the --hidden class for the dropdown in the mega menu. This removes the
      // display: none, we then wait 50 milliseconds to call the showMegaMenuDropdown, this approach allows
      // us to have a nice little transition state (opacity 0 to opacity 1), which we can't achieve going
      // directly from display: none to display: block;
      // It is called when you hover/focus on a Top Level Menu Item
      function activateMegaMenuDropdown() {
        $megaMenuDropdown.removeClass('mega-menu__dropdown--hidden');
        setTimeout(showMegaMenuDropdown, 50);
      }

      // This function removes the --active class for the dropdown and adds --inactive and --hidden
      // This is called when you hover off the main menu, or focus away from it.
      function hideMegaMenuDropdown() {
        $megaMenuDropdown.addClass('mega-menu__dropdown--inactive');
        $megaMenuDropdown.removeClass('mega-menu__dropdown--active');
        $megaMenuDropdown.addClass('mega-menu__dropdown--hidden');
      }

      function deactivateMainContentMenuItems() {
        $mainContentMenuItems.removeClass('mega-menu__dropdown-main-item--active');
        $mainContentMenuItems.addClass('mega-menu__dropdown-main-item--inactive');
      }

      $megaMenu.hover(function () {
        // Do nothing
      }, function () {
        hideMegaMenuDropdown();
      });

      // If you tab to anywhere on the page that is not the header__off-canvas div,
      // then hide the div.
      $('a, button, input').focus(function () {
        if ($(this).closest('.header__mega-menu--large-screens').length === 0) {
          hideMegaMenuDropdown();
        }
      });

      var timeout = null;
      $megaMenuTopLevelItems.mouseenter(function () {
        timeout = setTimeout(function () {
          timeout = 250;
          activateMegaMenuDropdown()
        }, 250);
      }).mouseleave(function () {
        if (timeout == null) {
          activateMegaMenuDropdown();
        }
        else {
          clearTimeout(timeout);
        }
      });

      $megaMenuTopLevelItems.focus(function () {
        activateMegaMenuDropdown();
      });

      ////////////////
      //////////////// When FOCUSSING on Top Level Mega Menu Items
      ////////////////
      // Here we check the unique ID of the top level menu item when we hover it.
      // If a sidebar item has the same parent ID (i.e. is a direct child of this item), we show it.
      // If it is not a direct child, we hide it (remove --visible and add --inactive class)
      $megaMenuTopLevelItems.each(function () {
        $(this).focus(function () {
          var $topLevelItemId = $(this).parents('.menu-item--level-top').data('menu-id');
          deactivateMainContentMenuItems();

          $sideBarMenuItems.each(function () {
            if ($(this).parent().data('parent-id') === $topLevelItemId) {
              var $selfId = $(this).parent().data('self-id');
              $(this).parent().addClass('menu-item__sidebar--visible');

              // When focussing a top level item, check the 'parent-id' of each of the main content area items.
              // If this parent-id matches the 'self-id' of the current visible sidebar item, show the first item.
              // This means it resets itself each time a top level menu item is focussed.
              $mainContentMenuItems.each(function () {
                if ($(this).data('parent-id') === $selfId) {
                  $(this).hasClass('mega-menu__dropdown-main-item--1') ? $(this).addClass('mega-menu__dropdown-main-item--active').removeClass('mega-menu__dropdown-main-item--inactive') : $(this).removeClass('mega-menu__dropdown-main-item--active').addClass('mega-menu__dropdown-main-item--inactive');
                }
              });

              // As each sidebar menu item is focussed, we check its unique ID. If a 'main area' item
              // has the same parent ID (i.e. is a direct child of the sidebar item), we show it.
              $(this).focus(function () {
                $sideBarMenuItems.parent().removeClass('menu-item__sidebar--active');
                $(this).parent().addClass('menu-item__sidebar--active');

                // If you press the right arrow key when focussed on a sidebar menu item, set focus to
                // the first link in the main content area.
                $(this).keydown(function (e) {
                  var $sidebarLevelKeyPressed = e.which;
                  if ($sidebarLevelKeyPressed == $rightKey) {
                    e.preventDefault();
                    $('.header__mega-menu--large-screens').find('.mega-menu__dropdown-main-item--active a:first').focus();
                  }
                });
                $mainContentMenuItems.each(function () {
                  if ($(this).data('parent-id') === $selfId) {
                    $(this).addClass('mega-menu__dropdown-main-item--active');
                    $(this).removeClass('mega-menu__dropdown-main-item--inactive');
                  } else {
                    $(this).addClass('mega-menu__dropdown-main-item--inactive');
                    $(this).removeClass('mega-menu__dropdown-main-item--active');
                  }
                });
              }); // End focus sidebar items to reveal main area items.
            } else {
              $(this).parent().removeClass('menu-item__sidebar--visible');
              $(this).parent().addClass('menu-item__sidebar--inactive');
            }
          });

          // When you press the down arrow key when focussed on a top level menu item.
          $(this).keydown(function (e) {
            var $topLevelKeyPressed = e.which;
            if ($topLevelKeyPressed === $downKey) {
              e.preventDefault();
              $('.header__mega-menu--large-screens .menu-level--sidebar')
                .find('.menu-item__sidebar--active[data-parent-id="' + $topLevelItemId + '"] > a')
                .focus();
            }
          });

        });
      });

      ////////////////
      //////////////// When HOVERING on Top Level Mega Menu Items
      ////////////////
      // Here we check the unique ID of the top level menu item when we hover it.
      // If a sidebar item has the same parent ID (i.e. is a direct child of this item), we show it.
      // If it is not a direct child, we hide it (remove --visible and add --inactive class)
      $megaMenuTopLevelItems.each(function () {
        $(this).hover(function () {

          var $topLevelItemId = $(this).parents('.menu-item--level-top').data('menu-id');
          deactivateMainContentMenuItems();

          $sideBarMenuItems.each(function () {
            if ($(this).parent().data('parent-id') === $topLevelItemId) {
              var $selfId = $(this).parent().data('self-id');
              $(this).parent().addClass('menu-item__sidebar--visible');

              // When hovering a top level item, check the 'parent-id' of each of the main content area items.
              // If this parent-id matches the 'self-id' of the current visible sidebar item, show the first item.
              // This means it resets itself each time a top level menu item is hovered.
              $mainContentMenuItems.each(function () {
                if ($(this).data('parent-id') === $selfId) {
                  $(this).hasClass('mega-menu__dropdown-main-item--1') ? $(this).addClass('mega-menu__dropdown-main-item--active').removeClass('mega-menu__dropdown-main-item--inactive') : $(this).removeClass('mega-menu__dropdown-main-item--active').addClass('mega-menu__dropdown-main-item--inactive');
                }
              });

              // As each sidebar menu item is hovered, we check its unique ID. If a 'main area' item
              // has the same parent ID (i.e. is a direct child of the sidebar item), we show it.
              $(this).hover(function () {
                $sideBarMenuItems.parent().removeClass('menu-item__sidebar--active');
                $(this).parent().addClass('menu-item__sidebar--active');
                $mainContentMenuItems.each(function () {
                  if ($(this).data('parent-id') === $selfId) {
                    $(this).addClass('mega-menu__dropdown-main-item--active');
                    $(this).removeClass('mega-menu__dropdown-main-item--inactive');
                  } else {
                    $(this).addClass('mega-menu__dropdown-main-item--inactive');
                    $(this).removeClass('mega-menu__dropdown-main-item--active');
                  }
                });
              }); // End hover sidebar items to reveal main area items.
            } else {
              $(this).parent().removeClass('menu-item__sidebar--visible');
              $(this).parent().addClass('menu-item__sidebar--inactive');
              $sideBarMenuItems.parent().removeClass('menu-item__sidebar--active');

            }
          });

        });
      });

    },
  };
}(jQuery, Drupal));
